import React from "react"
import PropTypes from "prop-types"
import styled from "styled-components"
import Item from "./item"

const StyledNavigation = styled.nav`
  display: flex;

  ${props =>
    props.isSearchOpen &&
    `
      overflow: hidden;
      height: 0;
    `};

  @media (max-width: 1025px) {
    overflow-y: auto;
    max-height: 40rem;
  }

  ul {
    display: flex;
    font-size: 1.5rem;
    list-style-type: none;
    margin: 0;

    @media (max-width: 1025px) {
      flex-direction: column;
      width: 100%;
      font-size: 1rem;
    }

    li {
      display: flex;
      margin: 0;
    }
  }
`

const Navigation = ({
  isSearchOpen = false,
  closeMenuAction = () => {},
  mainMenuItems = [],
}) => {
  return (
    <StyledNavigation isSearchOpen={isSearchOpen}>
      <ul>
        {mainMenuItems
          ?.filter(({ node }) => node?.topLevel)
          .sort((a, b) => a.node.order - b.node.order)
          .map(({ node }) => {
            return (
              <li key={node.contentful_id}>
                <Item
                  item={node}
                  items={mainMenuItems}
                  closeMenuAction={closeMenuAction}
                />
              </li>
            )
          })}
      </ul>
    </StyledNavigation>
  )
}

Navigation.propTypes = {
  isSearchOpen: PropTypes.bool,
  closeMenuAction: PropTypes.func,
  mainMenuItems: PropTypes.array,
}
export default Navigation
