import PropTypes from "prop-types"
import { globalHistory } from "@reach/router"
import React, { useEffect, useState } from "react"
import styled, { css } from "styled-components"
import { Link } from "gatsby"
import { getLanguage } from "../../../utils"

const StyledItem = styled.div`
  position: relative;
  display: flex;
  width: 100%;

  @media (max-width: 1024px) {
    font-size: 1rem;
    border-bottom: 0.1rem solid #cfcfcf;
  }

  @media (max-width: 1024px) {
    flex-direction: column;
  }

  &.disabled {
    &:after {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      content: "";
      z-index: 1;
    }
  }

  @media (min-width: 1024px) {
    &:hover,
    &:active,
    &:focus {
      .top-link {
        background-color: ${props => props.theme.crockpot.main};
      }

      .submenu {
        max-height: 35rem;
        opacity: 1;
      }
    }
  }
`

const sharedLinkStyle = css`
  display: inline-flex;
  flex-direction: row;
  align-items: center;
  font-weight: normal;
  font-size: 1rem;
  justify-content: center;
  padding: 1rem 1.5rem;
  transition: all 300ms linear;

  @media (min-width: 1024px) {
    flex-direction: column;
    height: 100%;
    font-size: 1.6rem;
    padding: 0 1.5rem;
  }

  > svg {
    @media (min-width: 1024px) {
      display: none;
    }

    margin-left: 0.5rem;
    transition: 200ms linear;
  }

  &.active {
    background: ${props => props.theme.crockpot.main};
  }

  @media (max-width: 1024px) {
    ${props =>
      props.open &&
      `
        outline: none;

        + .submenu {
          max-height: 35rem;
          opacity: 1;
        }
      `};
  }
`

const StyledTopLink = styled(props => <Link {...props} />)`
  ${sharedLinkStyle}
`

const StyledTopElement = styled.button`
  ${sharedLinkStyle}
  background: none;
  color: ${props => props.theme.crockpot.text};
  border: 0;
  cursor: pointer;

  svg {
    width: 100%;
    max-width: 0.5rem;
    transform: rotate(-90deg);

    path {
      fill: ${props => props.theme.crockpot.text};
    }
  }

  ${props =>
    props.open &&
    `
    svg{
      transform:rotate(90deg)
    }
  `}
`

const StyledSubmenu = styled.ul`
  display: flex;
  flex-direction: column;
  overflow: hidden;
  width: 100%;
  max-height: 0;
  min-width: 20rem;
  background: #fff;
  opacity: 0;
  transition: 300ms linear;

  @media (min-width: 1024px) {
    position: absolute;
    top: 100%;
    left: 0;
    line-height: 3rem;
    box-shadow: 0.3rem 0.3rem 0.6rem #0000004d;
    padding-bottom: 0;

    li {
      a {
        padding: 0.5rem 1.5rem;
      }

      &:hover {
        background: ${props => props.theme.crockpot.main};
      }
    }
  }

  @media (max-width: 1025px) {
    li:last-child {
      padding-bottom: 0.5rem;
    }
  }

  a {
    display: block;
    width: 100%;

    @media (max-width: 1025px) {
      text-align: center;
      padding: 0.6rem 0;
      font-size: 1rem;
    }
  }
`

const Item = ({ item = {}, items = [], closeMenuAction = () => {} }) => {
  const [open, setIsOpen] = useState(false)

  useEffect(() => {
    return globalHistory.listen(({ action }) => {
      if (action === "PUSH") setIsOpen(false)
    })
  }, [setIsOpen])

  const childrenItems = items
    .filter(child => child.node.parentItem?.title === item.title)
    .sort((a, b) => a.node.order - b.node.order)

  const subMenu = childrenItems
    .filter(item => item.node.url !== "#")
    .map(({ node }) => (
      <li key={node.id}>
        <Link
          to={`${getLanguage()}/${node.url}`}
          onClick={() => {
            setIsOpen(false)
            closeMenuAction()
          }}
        >
          {node.title}
        </Link>
      </li>
    ))

  const toggleActive = () => {
    setIsOpen(!open)
  }

  const title = item.title

  return (
    <StyledItem key={item.contentful_id}>
      {item.url === "#" ? (
        <>
          <StyledTopElement
            className="top-link"
            data-text={title}
            onClick={toggleActive}
            open={open}
          >
            {title}
            {childrenItems.length > 0 && (
              <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 256 512">
                <path d="M24.707 38.101L4.908 57.899c-4.686 4.686-4.686 12.284 0 16.971L185.607 256 4.908 437.13c-4.686 4.686-4.686 12.284 0 16.971L24.707 473.9c4.686 4.686 12.284 4.686 16.971 0l209.414-209.414c4.686-4.686 4.686-12.284 0-16.971L41.678 38.101c-4.687-4.687-12.285-4.687-16.971 0z" />
              </svg>
            )}
          </StyledTopElement>
          {childrenItems.length > 0 && (
            <StyledSubmenu className="submenu">{subMenu}</StyledSubmenu>
          )}
        </>
      ) : (
        <StyledTopLink
          to={`${getLanguage()}/${item.url}`}
          className="top-link"
          data-text={title}
          onClick={() => {
            setIsOpen(false)
            closeMenuAction()
          }}
          activeClassName="active"
        >
          {title}
        </StyledTopLink>
      )}
    </StyledItem>
  )
}

Item.propTypes = {
  item: PropTypes.object,
  items: PropTypes.array,
  closeMenuAction: PropTypes.func,
}

export default Item
